div.logo {
    background-color: #FF7D4A;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 32px;
    width: 32px;
    line-height: 25px;
}

span.logo  {
    margin-left: 44px;
    font-size: 24px;
    color: #FF7D4A;
}

.navbar {
    margin: auto;

}
.navbarLink {
    color: #6595E5;
    padding-left: 15px;
}
img.logo {
    height: 26px;
}