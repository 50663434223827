@import url('https://fonts.googleapis.com/css?family=Rubik:300&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.buttons-footer {
  display: flex;
  justify-content: space-between;
}
.mainBody {
  width: 1000px;
  margin: 15px;
  justify-content: center;
}
.container {
  width: 100%;
}
.flex-header{
  display: flex;
  align-items: center;
  justify-content: center;
    margin: 10px;
    padding: 10px;
}
.client-container{
  display: flex;
  justify-content: space-between;
}
.client-left{
  width: 50%;
  margin: 5px;
}
.client-right{
  width: 50%;
  margin: 5px;
}
.client-dates-cotainer {
  display: flex;
  justify-content: space-between;
}
#root {
    background: #EAEAF0;
    font-family: 'Rubik', sans-serif;
}
html {
    background: #EAEAF0;
    font-family: 'Rubik', sans-serif;

}
.flex-content {
  display: flex;
  justify-content: space-between;
}

.logo-image-preview{
  width: 200px;
}